import { login, forgotPassword, loginResponse, loginClear, AuthCheck } from "./action/Login"
import { getStatistics } from "./action/dashboard"
import { getAllBoxes, getBoxesStatus, getPortals, clearPortalsResponse, changeAddEditBoxFilters } from "./action/addEditBox"
import { getAllVendorUser, changeVendorUserFilters } from "./action/vendorUser"
import { getActivityLog, clearActivityLog} from "./action/activityLog"

export const actionsApi = {
    login, 
    loginResponse,
    forgotPassword,
    loginResponse, 
    loginClear, 
    AuthCheck, 
    //dashboard
    getStatistics,
    //addandroidbox 
    // getBoxStatistics,
    getAllBoxes, 
    getBoxesStatus,
    getPortals,
    clearPortalsResponse,
    changeAddEditBoxFilters,
    //vendorUser
    getAllVendorUser, 
    changeVendorUserFilters,
    //activityLog
    getActivityLog,
    clearActivityLog
}
