
import { useEffect } from "react"
import { Form, Space, Dropdown, Button} from "antd"
import { useSelector, useDispatch } from "react-redux"
import { FilterOutlined } from "@ant-design/icons"
import { MyInput } from "../../../Forms"
import { actionsApi } from "../../../../shared"
let timer

const Filter= ({userData})=>{

    const {filters, pagination} = useSelector(state => state?.vendorUser)
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        }
    ]
    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        filter(key)
    }

    useEffect(()=>{
        // if(userData && userData?.role==="masterAdmin"){
        //     dispatch(actionsApi?.getAllVendorUser({ ...filters, ...pagination, pageNo: 1 }))
        // }
        // else{
        //     dispatch(actionsApi?.getAllVendorUser({ ...filters, ...pagination, pageNo: 1 }))
        // }
        if(userData){
        dispatch(actionsApi?.getAllVendorUser({ ...filters, ...pagination, pageNo: 1 ,role:userData?.role}))
        }
        form.setFieldsValue({...filters})
    },[])

    const filter = (order) => {
        let data = form.getFieldsValue()
        dispatch(actionsApi?.getAllVendorUser({ ...data, order, ...pagination,role:userData?.role}))
        dispatch(actionsApi?.changeVendorUserFilters({ ...data, order }))
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    function searchHandler(_, pageNo=1, pageSize=20) {
        let data= form.getFieldsValue()
        console.log("search handler",data)
        dispatch(actionsApi?.getAllVendorUser({ ...filters, ...data, pageNo, pageSize,role:userData?.role }))
        dispatch(actionsApi?.changeVendorUserFilters({ ...filters, ...data}))
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)

    return (
        <Form
            form={form}
            layout="vertical"
        >
            <Space className='align-center mbl-wrap'>
                <MyInput
                    name='name'
                    label={userData.role==="masterAdmin"?'Master Vendor':'Vendor user'}
                    placeholder='e.g John'
                    style={{width: '220px'}}
                    onChange={debouncedSearchHandler}
                />
                <Dropdown
                    menu={{
                        items,
                        onClick
                    }}
                    trigger={['click']}
                    arrow
                    icon={<FilterOutlined />}
                >
                    <Button
                        className='margin-top'
                        icon={<FilterOutlined />}
                    >
                        Filter
                    </Button>
                </Dropdown>
            </Space>
        </Form>
    )
}
export {Filter}