import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Typography,Space, Form, Dropdown, Table, Button } from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading, MyInput } from '../../components'
import { DeleteOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import { actionsApi ,ApiCalls,TableLoader} from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
const { Text } = Typography


const AppTable = ({userData}) => {
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const { data:dat, loading, filters, pagination } = useSelector(state => state?.addEditBox)
    const [ visible, setVisible ] = useState(false)
    const [ visiblemodal, setVisibleModal] = useState(false)
    const [ dashname, setDashName ] = useState()

    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Registration date</a>,
            key: '2',
        },
    ];
    useEffect(()=>{
        call()
    },[])

    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAllBoxes({ ...filters, pageNo, pageSize }))
    }
   
    // function searchHandler(name, pageNo=1, pageSize=20) {
    //     dispatch(actionsApi?.getAddBoxDetail({ ...filters, pageNo, pageSize }))
    // }

    // const onClick = ({ key }) => {
    //     key = parseInt(key) + 1
    //     setOrder(key)
    //     filter(key)
    // };

    // const onSelectChange = (selectedRowKeys) => {
    //     setSelectedRowKeys(selectedRowKeys);
    //     console.log('Selected Row Id', selectedRowKeys)
    // };

    // function debounce(func, delay) {
    //     return function(...args) {
    //         clearTimeout(timer)
    //         timer = setTimeout(() => {
    //             func.apply(this, args)
    //         }, delay)
    //     };
    // }
    // const debouncedSearchHandler = debounce(searchHandler, 400)


      const columns = [
        {
            title: <Text>DEVICES NAME</Text>,
            dataIndex: 'boxName',
            key: 'boxName',
        },
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macAddress',
            key: 'macAddress',
        },
        {
            title: <Text>VENDOR NAME</Text>,
            dataIndex:userData && (userData.role==="masterAdmin"?'vendorName': userData.role==="masterVendor"?'customerName':'addedByName'),
            key: userData && (userData.role==="masterAdmin"?'vendorName': userData.role==="masterVendor"?'customerName':'addedByName'),
        },
        {
            title: <Text>TIME STAMP</Text>,
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
        {
            title: <Text>ACTION</Text>,
            key: 'action',
            align:'center',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit Box'
                        icon={<EditOutlined className='green-color'/>}
                        onClick={()=>{setVisible(true)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color'/>}
                        onClick={()=>{setVisibleModal(true); setDashName({id:row?.id, name: row?.boxName})}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
          },
      ];
    //   const data = [
    //     {
    //         key: '1',
    //         devname: <Text>AD12</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //     {
    //         key: '2',
    //         devname: <Text>AD13</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //     {
    //         key: '3',
    //         devname: <Text>AD14</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //     {
    //         key: '4',
    //         devname: <Text>AD15</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //     {
    //         key: '5',
    //         devname: <Text>AD16</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //     {
    //         key: '6',
    //         devname: <Text>AB17</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //     {
    //         key: '7',
    //         devname: <Text>AD18</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //     {
    //         key: '8',
    //         devname: <Text>AD19</Text>,
    //         macaddress: <Text>00:B0:D0:63:C2:26</Text>,
    //         vendorName: <Text>Nazli Hasan</Text>,
    //         remainingtoken: <Text>23/02/2023 15:03</Text>
    //     },
    //   ];

    const removeRow= async (ID)=>{
        const result = await ApiCalls?.AddEditBoxApi?.DeleteAndroidApi(ID)
        if(result){
            dispatch(actionsApi?.getStatistics())
            return 1
        }
        else    
        return 0
    }
  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col span={24}>
                    <ModuleTopHeading
                        name='Latest registered devices'
                    />
                </Col>
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Space  className='align-center mbl-wrap' >
                            <MyInput
                                name='vendorName'
                                label='Vendor Name'
                                placeholder='e.g John Doe'
                                value={form.getFieldValue("vendorName") || ''} 
                                style={{width: '220px'}}
                                // onChange={(e)=> debouncedSearchHandler(e.target.value, 1, 20)}
                            />
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                                <Button
                                    className='margin-top'
                                    icon={<FilterOutlined />}
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Space>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={dat || []} 
                        style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                        scroll={{x: 1000}}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={
                            {
                                ...TableLoader,
                                spinning: loading
                            }
                        }
                    />
                </Col>
            </Row>
        </Card>

        {/* <AddEditDrawer
            visible={visible}
            onClose={()=> {setVisible(false)}}
        /> */}
        <AlertModal
            visible={visiblemodal}
            row={dashname}
            removeRow={removeRow}
            endCall={call}
            onClose={()=>{setVisibleModal(false); setDashName(null)}}
        />
         
    </div>
  )
}

export {AppTable}