import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, Table, Typography } from 'antd'
import { ApiCalls, actionsApi } from '../../../shared'
const { Text } = Typography

const CsvModal = ({visible, editBoxes, csvData, onClose}) => {

    const dispatch= useDispatch()
    const { filters, pagination } = useSelector(state => state?.addEditBox)
    const [data, setData]= useState([])
    const [vendorID, setVendorID]= useState(null)
    const [loading, setLoading]= useState(false)

    const columns = [
        {
            title: <Text>serial #</Text>,
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: <Text>Box Name</Text>,
            dataIndex: 'boxName',
            key: 'boxname',
        },
        {
            title: <Text>Master Portal</Text>,
            dataIndex: "masterPortal",
            key: "masterPortal",
        },
        {
            title: <Text>Mac Address</Text>,
            dataIndex: "macAddress",
            key: "macAddress",
        },
        {
            title: <Text>Update Url</Text>,
            dataIndex: "updateUrl",
            key: "updateUrl",
        },
        {
            title: <Text>Customer Name</Text>,
            dataIndex: "customerName",
            key: "customerName",
        }
    ]
    useEffect(()=>{
        let user= localStorage.getItem("user")
        user= JSON.parse(user)
        setVendorID(user?.id)
    }, [])
    useEffect(()=>{
        if(csvData?.length){
            let columns= csvData[0]
            let temp= [...csvData?.filter((_, index)=> index>0)]
            for(let i=0; i < temp?.length; i++){
                let obj= {}
                for(let j=0; j<temp[i]?.length; j++)
                    obj={...obj, [columns[j]]: temp[i][j]}
                temp[i]= obj
            }
            setData(temp?.filter(fil => fil?.boxName))
        }
        else
            setData([])
    }, [csvData])
    const saveUpdateMultipleBpxes= async ()=>{
        setLoading(true)
        const result= await ApiCalls?.AddEditBoxApi?.saveUpdateMultipleBpxes({vendorID, editBoxes, devices: data})
        if(result){
            onClose()
            dispatch(actionsApi?.getAllBoxes({ ...filters, ...pagination }))
        }
        setLoading(false)
    }
  return (
    <Modal
        title="Add mutiple android boxes"
        centered
        open={visible}
        onCancel={onClose}
        footer={[
            <Button loading={loading} type='primary' onClick={saveUpdateMultipleBpxes}>Add Boxes</Button>
        ]}
        width={950}
      >
        <Table
            size='large'
            columns={columns} 
            dataSource={data}     
            pagination={{ 
                hideOnSinglePage: true, 
                total: csvData?.length,
                pageSize: 10,
                defaultPageSize: 10,
                size: "default",
                pageSizeOptions:['20', '50', '100'],
                showTotal: total => <Button>Total: {total}</Button>
            }}
            // scroll={{y: 400}}
        />

        
    </Modal>
  )
}

export {CsvModal}