import { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography} from "antd"
import { ApiCalls } from "../../../shared/ApiCalls"
import { checkAuthorization, domainUrl } from "../../../shared"
import { MyInput } from "../../Forms"
import { useSelector } from "react-redux"
const {Text}= Typography

const AddVendorUser = ({visible, onClose, editvendor, getVendorApplications}) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [tokenDetail, setTokenDetail]= useState(null)
    const [currentToken, setCurrentToken]= useState(0)
    const {userData}= useSelector(state => state?.login)
   
    useEffect(()=>{
        if(visible && userData.role==="masterVendor")
            getTokenDetail()
        if(visible && editvendor)
            EditVendorApi(editvendor)
        else{
            form.resetFields()
            setTokenDetail(null)
        }
    },[visible, editvendor])

    const getTokenDetail= async ()=>{
        const {userID}= checkAuthorization()
        
        let result= await ApiCalls?.VendorUserApi?.getTokenDetail(userID)
        if(result)
            setTokenDetail(result)
        else
            setTokenDetail(null)
    }
    const AddUpdateCreateVendor = async (formData) => {
        setLoading(true)
        try {
            const result = await ApiCalls.VendorUserApi.AddUpdateCreateVendor({...formData,id:editvendor,userRole:userData && userData?.role})
            setLoading(false);
            if(result){
                onClose();
                getVendorApplications();
            }
        } catch (error){
            console.error("Error updating data: ",error)
        }
    }

    // edit vendor api
    const EditVendorApi = (id) => {
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        let url = userData.role==="masterAdmin"?`/getVendor/${id}`:`/editVendorUser/${id}`
       
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success){
                let response = userData.role==="masterAdmin"?result?.vendor:result?.vendorUser
                form.setFieldsValue({...response})
                setCurrentToken(parseInt(response?.token))
            }
            else
                throw  'error'
        })
        .catch(error => {alert(error)})
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields()
            AddUpdateCreateVendor(formData)
        } catch (error) {
            console.error("Form validation error:", error)
        }
    }
    
  return (
    <div>
        <Drawer
            title={(userData && userData.role === "masterAdmin")
                ? (editvendor ? "Update Master Vendor" : "Add Master Vendor")
                : (editvendor ? "Update Vendor User" : "Add Vendor User")}
            onClose={onClose}
            open={visible}
            width={700}
            footer={
                <Space className="justify-end">
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button  
                        type="primary"
                        htmlType="submit"
                        onClick={()=>form.submit()}
                        loading={loading}
                    >
                        {
                            editvendor ? 'Update vendor user' : 'Add vendor user'
                        }
                    </Button>
                </Space>
            }
        >
            {(userData && userData?.role==="masterVendor") && 
             <Space direction="vertical" className="mb-3">
             <Space>
                 <Text strong>Total Token: </Text>
                 <Text>{tokenDetail?.totalToken || '-'}</Text>
             </Space>
             <Space>
                 <Text strong>Remaining Token: </Text>
                 <Text>
                     {
                         editvendor ?
                         (tokenDetail?.remainingToken + currentToken) :
                         tokenDetail?.remainingToken
                     }
                 </Text>
             </Space>
         </Space>
            }
           
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            autoFocus
                            name='name'
                            label='Vendor name'
                            required
                            message='Please enter name'
                            placeholder='e.g Jhone'
                            value={form.getFieldValue('name')}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Password'
                            type='password'
                            name='password'
                            required
                            message={()=>{}}
                            placeholder='e.g A@123456'
                            value={form.getFieldValue("password") || ''}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            validator={({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                    if (!reg.test(value)) {
                                        return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            name='token'
                            label='Total Token'
                            required
                            message='Please enter token!'
                            placeholder='e.g 200'
                            validator={({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    if(editvendor) {
                                        if(value > (tokenDetail?.remainingToken + currentToken))
                                            return Promise.reject(new Error('assigned token should be less then or equal to remaining token'))
                                        else 
                                            return Promise.resolve()                                    
                                    }
                                    else{
                                        if (value > tokenDetail?.remainingToken) 
                                            return Promise.reject(new Error('assigned token should be less then or equal to remaining token'))
                                        else 
                                            return Promise.resolve()
                                    }
                                }
                            })}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            name='email'
                            label='Email Address'
                            required
                            message='Please enter email!'
                            placeholder='e.g abc@example.com'
                            value={form.getFieldValue('email')}
                        />
                    </Col>
                </Row>
            </Form>
        </Drawer>
    </div>
  )
}

export {AddVendorUser}