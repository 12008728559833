import moment from "moment";

export function timeDifference(givenTime) {
    const currentTime = new Date();
    const givenDate = new Date(givenTime)
    const differenceInMilliseconds = currentTime - givenDate;
    console.log("mili:", differenceInMilliseconds,":", givenDate)
    return differenceInMilliseconds;
}
export const utcToLocal=(dateTime)=>{
    if(!dateTime)
    return
    return (moment.utc(dateTime).local().format('MM/DD/YYYY HH:mm'))
}