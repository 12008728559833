import { message } from "antd";
import { domainUrl } from "../../constants";
import { checkAuthorization } from "../../helpers";


const updateCreateApi = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
   console.log(formData)
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const url = formData.id ? `/updateAndroidBox` : '/createAndroidBox';
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            if (result?.success) {
                message.success(result?.message || 'Android Box Inserted Successfully');
                return 1
            }
            else {
                message.error(result?.message || 'Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error || "Something went wrong!!");
            return 0
        })
    )
};
const getPortals= (boxID)=>{

    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    const url = `/getPortals/${boxID}`
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => {
            if(response?.status!== 200)
                throw "auth error"
            return response.json()
        })
        .then(result => {
            if (result?.success) {
                return result?.data
            }
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return []
        })
    )
}
const saveUpdateMultipleBpxes = (data) => {

    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append('Authorization', userToken)
    
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    };
    const url = data?.editBoxes ? "/updateMultipleBoxes" : "/uploadMultiple"
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
};

const DeleteAndroidApi=(id)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteAndroidBox/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success('Android Box deleted Successfully !!')
                return 1
            } 
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error.message)
            return 0
        })
    )
  }


const AddEditBoxApi = {
    updateCreateApi,
    saveUpdateMultipleBpxes,
    DeleteAndroidApi,
    getPortals
}

export {AddEditBoxApi}