export const boxCsvSampleData= [
    {serialNo: "1239dkaf22", boxName: "box1005", masterPortal: "yes", macAddress: "02:00:00:00:00:00", updateUrl: "Allowed", customerName: "Shujat Ali"},
    {serialNo: "1139dkaf25", boxName: "box1006", masterPortal: "no", macAddress: "03:00:00:00:00:00", updateUrl: "not Allowed", customerName: "Shujat Ali"},
    {serialNo: "2239dkaf33", boxName: "box1007", masterPortal: "no", macAddress: "04:00:00:00:00:00", updateUrl: "Allowed", customerName: "Muhammad Aqib"},
    {serialNo: "7239dkaf00", boxName: "box1008", masterPortal: "yes", macAddress: "22:00:00:00:00:00", updateUrl: "Allowed", customerName: "Usman Ali"},
    {serialNo: "3339dkafC1", boxName: "box1009", masterPortal: "yes", macAddress: "22:00:00:00:00:00", updateUrl: "Allowed", customerName: "Shujat Ali"},
    {serialNo: "1129dhaf55", boxName: "box1009", masterPortal: "yes", macAddress: "27:00:00:00:00:00", updateUrl: "Allowed", customerName: "Shujat Ali"},
    {serialNo: "0009dkafC1", boxName: "box1009", masterPortal: "yes", macAddress: "99:00:00:00:00:00", updateUrl: "Allowed", customerName: "Shujat Ali"},
    {serialNo: "3339wwafC1", boxName: "box1009", masterPortal: "yes", macAddress: "12:10:00:00:00:00", updateUrl: "Allowed", customerName: "Abdullah Iqbal"},
    {serialNo: "3339ssafC1", boxName: "box1009", masterPortal: "no", macAddress: "33:00:00:00:00:00", updateUrl: "not Allowed", customerName: "John Doe"},
    {serialNo: "3339dzafC1", boxName: "box1009", masterPortal: "yes", macAddress: "44:00:00:00:00:00", updateUrl: "not Allowed", customerName: "Muhammad Azeem"},
]