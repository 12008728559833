
import { useEffect, useState } from "react"
import { Form, Space, Dropdown, Button} from "antd"
import { useSelector, useDispatch } from "react-redux"
import { FileOutlined, FilterOutlined } from "@ant-design/icons"
import { MyInput } from "../../../Forms"
import { actionsApi } from "../../../../shared"
import { ActivityLogDrawer } from "../../Drawer"
let timer

const Filter= ()=>{

    const {filters, pagination} = useSelector(state => state?.addEditBox)
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const [visible, setVisible]= useState(false)
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        }
    ]
    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        filter(key)
    }

    useEffect(()=>{
        dispatch(actionsApi?.getAllBoxes({ ...filters, ...pagination, pageNo: 1 }))
        form.setFieldsValue({...filters})
    },[])

    const filter = (order) => {
        let data = form.getFieldsValue()
        
        dispatch(actionsApi?.getAllBoxes({ ...data, order, ...pagination}))
        dispatch(actionsApi?.changeAddEditBoxFilters({ ...data, order }))
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    function searchHandler(_, pageNo=1, pageSize=20) {
        let data= form.getFieldsValue()
       
        dispatch(actionsApi?.getAllBoxes({ ...filters, ...data, pageNo, pageSize }))
        dispatch(actionsApi?.changeAddEditBoxFilters({ ...filters, ...data}))
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)

    return (
        <>
            <Form
                form={form}
                layout="vertical"
            >
                <Space className='align-center mbl-wrap'>
                    <MyInput
                        name='boxName'
                        label='Box name'
                        placeholder='e.g Box 1002'
                        style={{width: '220px'}}
                        onChange={debouncedSearchHandler}
                    />
                    <MyInput
                        name='serialNo'
                        label='Serial no'
                        placeholder='e.g 2bcdefg778'
                        style={{width: '220px'}}
                        onChange={debouncedSearchHandler}
                    />
                    <MyInput
                        name='customerName'
                        label='Customer name'
                        placeholder='e.g John Doe'
                        style={{width: '220px'}}
                        onChange={debouncedSearchHandler}
                    />
                    {/* <MySelect
                        label= 'Device status'
                        name="status" 
                        placeholder='select status'
                        options={[
                            {name:"online", id: 1},
                            {name:"offline", id: 2},
                        ]}
                        style={{width: '180px'}}
                        onChange={()=>{searchHandler("")}}
                    /> */}
                    <Dropdown
                        menu={{
                            items,
                            onClick
                        }}
                        trigger={['click']}
                        arrow
                        icon={<FilterOutlined />}
                    >
                        <Button
                            className='margin-top'
                            icon={<FilterOutlined />}
                        >
                            Filter
                        </Button>
                    </Dropdown>
                    <Button
                        type='primary'
                        icon={<FileOutlined />}
                        onClick={()=> setVisible(true)}
                        className='btn-color-other translate-down'
                    >
                        Activity log
                    </Button>
                </Space>
            </Form>
            <ActivityLogDrawer visible={visible} onClose={()=> setVisible(false)}/>
        </>
    )
}
export {Filter}