import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Space, Row, Col, Card, Typography, Table } from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading } from '../../components'
import { AddVendorUser, Filter } from '../../components/VendorUser'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ApiCalls, TableLoader, actionsApi } from '../../shared'

const { Text } = Typography

const VendorUser = () => {

    const dispatch = useDispatch()
    const { data, loading, filters, pagination } = useSelector(state => state?.vendorUser)
    const [visible, setVisible]= useState(false)
    const [visiblemodal, setVisibleModal] = useState(false);
    const [editvendor, setEditVendor] = useState(null);
    const [ deletevendor, setDeleteVendor ] = useState(null)
     const {userData}= useSelector(state => state?.login)
    
    const call = (pageNo = 1, pageSize = 10) => {
         dispatch(actionsApi?.getAllVendorUser({ ...filters, pageNo, pageSize,role:userData?.role }))
    }

  const columns = [
        {
            title: <Text>VENDOR NAME</Text>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <Text>EMAIL ADDRESS</Text>,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: <Text>REMAINING TOKEN</Text>,
            dataIndex: 'token',
            key: 'token',
        },
        {
          title: <Text>ACTION</Text>,
          key: 'action',
          fixed:'right',
          width:100,
          align:'center',
          render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit Item'
                        icon={<EditOutlined className='green-color'/>}
                        onClick={()=>{setVisible(true);setEditVendor(row?.id)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Item'
                        icon={<DeleteOutlined className='danger-color'/>}
                        onClick={()=>{setVisibleModal(true);setDeleteVendor({id: row?.id, name: row?.name})}}
                        type='ghost'
                        danger
                    />
                </Space>
          ),
        },
    ]
    const removeRow= async (ID)=>{
        const result = await ApiCalls?.VendorUserApi?.deleteVendorUser({ID , role:userData?.role})
        if(result)
            return 1
        return 0
    }



  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col span={24}>
                    <ModuleTopHeading 
                        name={(userData && userData.role==="masterAdmin")?'Master Vendors':'Vendor Users'}
                        onClick={()=>setVisible(true)}
                    />
                </Col>
                <Col span={24}>
                    <Filter userData={userData}/>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        className='table-cover'
                        scroll={{x: 1000}}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={
                            {
                                ...TableLoader,
                                spinning: loading
                            }
                        }
                    />
                </Col>
            </Row>
            <AddVendorUser
                visible={visible}
                editvendor={editvendor}
                getVendorApplications={call}
                userData={userData}
                onClose={()=> {setVisible(false);setEditVendor(null)}}
            />
            <AlertModal
                visible={visiblemodal}
                row={deletevendor}
                removeRow={removeRow}
                endCall={call}
                onClose={()=>{setVisibleModal(false);setDeleteVendor(null)}}
            />
        </Card>
    </div>
  )
}

export {VendorUser}