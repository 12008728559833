import { useState } from 'react'
import { Card, Space, Typography, Button } from 'antd'
// import { IptvCheckbox } from '../checkbox'
import CSVReader from './test';
import { CSVFormatGuideModal } from '../../Modals';
import './index.css'

const ImportDevices = () => {

  const [visible, setVisible]= useState(false)

  return (
    <div className='pt-3'>
        <Card
          className='radius-12'
          actions={[
            <Button type='primary' onClick={()=> setVisible(true)}>
                Sample format
            </Button>
          ]}
        >
            <Space direction='vertical' size={8} className='w-100'>
                <div>
                  <Typography.Title level={4} className='text-capitalize'>Import devices</Typography.Title>
                  {/* <IptvCheckbox
                    name='masterportal'
                    label='Include master portal'
                  /> */}
                </div>
                <Space align='center' size={10}>
                    <Typography.Title level={5} className='m-0'>Import CSV</Typography.Title>
                    <Typography.Title level={5} className='m-0 brand-color'>Maximum 200 devices import at one time</Typography.Title>
                </Space>
                <div className='justify-center myclass'>
                    <Space size={15} className='csv-reader-space'>
                        <CSVReader/>
                        <CSVReader editBoxes/>
                    </Space>
                </div>
            </Space>
        </Card>
        <CSVFormatGuideModal visible={visible} onClose={()=> setVisible(false)} />
    </div>
  )
}

export {ImportDevices}