import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Row, Col, Form, Button, Space } from "antd"
import { MyInput, MySelect } from "../../Forms"
import { methodUrlType } from "../../../shared/lookups"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"

const Portals= ({form})=>{

    const {portals}= useSelector(state => state?.addEditBox)
    const [urlNumber, setUrlNumber]= useState([])
  
    useEffect(()=>{
        if(portals?.length){
            form.setFieldsValue({
                ...form.getFieldsValue(),
                portals: portals?.map(portal =>({
                    urlType: methodUrlType?.find(fin=> fin?.id=== parseInt(portal?.methodType))?.name, 
                    url: portal?.url, 
                    selectUrl: portal?.selectUrl, 
                    name: portal?.name, 
                    userName: portal?.userName, 
                    password: portal?.password
                }))
            })
            let tempArr= [...portals?.map((portal, index) =>({key: index, value: methodUrlType?.find(fin=> fin?.id=== parseInt(portal?.methodType))?.name}))]
            setUrlNumber(tempArr?.filter(fil=> fil?.value==="Xtreme Code API"))
        }
    }, [portals])
    return (
        <Form.List name="portals">
            {
                (fields, { add, remove }) => (
                    <Space direction="vertical" className="width-100">
                        <Button 
                            type="dashed" 
                            onClick={() => add()} block icon={<PlusOutlined />}
                            disabled={fields?.length>=5}
                        >
                            Add portal
                        </Button>
                        {
                            fields?.map(({ key, name}) => (
                                <Row key={key} gutter={[16, 0]} align="middle">
                                    <Col span={11}>
                                        <MySelect 
                                            label= {'URL '+(name+1)+' Type'}
                                            name={[name, 'urlType']}
                                            required
                                            showSearch
                                            message='Please choose url type!'
                                            placeholder='Select URL Type'
                                            value={form.getFieldValue('urlType') || ''}
                                            options={methodUrlType}
                                            onChange={(value)=> {
                                                if(value==="Xtreme Code API")
                                                    setUrlNumber([...urlNumber, {key, value}])
                                                else
                                                setUrlNumber(urlNumber?.filter(fil => fil?.key !== key))
                                            }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <MyInput
                                            label={'Name '+(name+1)}
                                            name={[name, 'name']}
                                            placeholder='e.g my m3u'
                                            required
                                            message='Please enter name'
                                            value={form.getFieldValue("name") || ''}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <MyInput
                                            label={'URL '+(name+1)}
                                            name={[name, 'url']}
                                            placeholder='e.g www.example.com'
                                            required
                                            message='Please enter url'
                                            value={form.getFieldValue("url") || ''}
                                        />
                                    </Col>
                                    {
                                        urlNumber?.find(fin=> fin?.key===key)!== undefined  && urlNumber?.find(fin=> fin?.key===key)?.value==="Xtreme Code API"?
                                        <>
                                            <Col span={11}>
                                                <MyInput
                                                    label='Username'
                                                    name={[name, 'userName']}
                                                    placeholder='e.g myapi.er'
                                                    required
                                                    message='Please enter username'
                                                    value={form.getFieldValue("userName") || ''}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <MyInput
                                                    label='Password'
                                                    name={[name, 'password']}
                                                    placeholder='e.g myapi.er'
                                                    required
                                                    message='Please enter password'
                                                    value={form.getFieldValue("password") || ''}
                                                />
                                            </Col>
                                        </>
                                        :
                                        <></>
                                    }
                                    <Col span={1} className="justify-end">
                                        <MinusCircleOutlined onClick={() => remove(name)}/>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Space>
                )
            }
        </Form.List>
    )
}
export default Portals