import React, { useEffect, useState } from 'react'
import { AppTable, CardComponent } from '../../components'
import { Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi } from '../../shared'

const Dashboard = () => {
  const dispatch= useDispatch()
  const {userData}= useSelector(state => state?.login)
  return (
    <Space direction='vertical' size={20} className='w-100'>
        <CardComponent userData = {userData}/>
        <AppTable  userData = {userData} />
    </Space>
  )
}

export {Dashboard}