import React, { useState } from 'react'
import { Image, Modal, Space, Typography, Button } from 'antd'
import { ApiCalls } from '../../shared/ApiCalls'
const { Title, Text } = Typography
const AlertModal = ({visible,onClose,row, removeRow, endCall, showname, dashname}) => {

    const [ loading, setLoading ] = useState(false)

    const DeleteAndroidApi = async()=> {
        setLoading(true)
        let result= await removeRow(row?.id)
        setLoading(false)
        if(result){
            onClose()
            endCall()
        }
    }

  return (
    <div>
        <Modal 
            width={600} 
            className='shadow-c modal'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={[
                <Button
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button  
                    type="primary"
                    loading={loading}
                    onClick={DeleteAndroidApi}
                >
                    Confirm
                </Button>
            ]}
        >
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <Image src='/assets/icons/alert-ic.png' width={'70px'} preview={false} />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='text-input text-center'>
                    Are you sure you want to permanently delete the <strong>"{row ? row?.name: showname ? showname : dashname}"</strong> record.
                </Text>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}