import React from 'react'
import { Space, Row, Col, Form, Typography,Dropdown, Card, Table, Button } from 'antd'
import {ModuleTopHeading, MyInput } from '../../components';
import { FilterOutlined } from '@ant-design/icons';

const { Text } = Typography

let timer
const TransactionReport = () => {
    const [form] = Form.useForm()
  
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Most downloads</a>,
            key: '2',
        },
        {
            label: <a href="#">Less downloads</a>,
            key: '3',
        },
    ];

    // function searchHandler(name, pageNo=1, pageSize=20) {
    //     dispatch(actionsApi?.getAddBoxDetail({ ...filters, pageNo, pageSize }))
    // }

    // const onClick = ({ key }) => {
    //     key = parseInt(key) + 1
    //     setOrder(key)
    //     filter(key)
    // };

    // const onSelectChange = (selectedRowKeys) => {
    //     setSelectedRowKeys(selectedRowKeys);
    //     console.log('Selected Row Id', selectedRowKeys)
    // };

    // function debounce(func, delay) {
    //     return function(...args) {
    //         clearTimeout(timer)
    //         timer = setTimeout(() => {
    //             func.apply(this, args)
    //         }, delay)
    //     };
    // }
    // const debouncedSearchHandler = debounce(searchHandler, 400)

  
  
    const columns = [
        {
            title: <Text>BOX NAME</Text>,
            dataIndex: 'boxName',
            key: 'boxName',
        },
        {
            title: <Text>ACTIVITY</Text>,
            dataIndex: 'activity',
            key: 'activity',
        },
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macAddress',
            key: 'macAddress',
        },
        {
            title: <Text>TIME STAMP</Text>,
            dataIndex: 'timeStamp',
            key: 'timeStamp',
        },
        {
            title: <Text>VENDOR NAME</Text>,
            dataIndex: 'vendorName',
            key: 'vendorName',
            width:150,
        },
      ];
      const data = [
          {
              key: '1',
              boxName:   <Text>AD12</Text>,
              activity:   <Text>Box Added</Text>,
              macAddress:   <Text>00:B0:D0:63:C2:26</Text>,
              timeStamp:   <Text>23/03/2023 11:04</Text>,
              vendorName:   <Text>Asli Hayat</Text>,
          },
          {
            key: '2',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:26</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '3',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:26</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '4',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:26</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '5',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:26</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
        {
            key: '6',
            boxName:   <Text>AD12</Text>,
            activity:   <Text>Box Added</Text>,
            macAddress:   <Text>00:B0:D0:63:C2:26</Text>,
            timeStamp:   <Text>23/03/2023 11:04</Text>,
            vendorName:   <Text>Asli Hayat</Text>,
        },
      ];
  
    return (
        <div>
            <Card className='radius-12 border0'>
                <Row gutter={[12,12]}>
                    <Col span={24}>
                        <ModuleTopHeading 
                            name='Transaction Report'
                        />
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Space  className='align-center mbl-wrap' >
                                <MyInput
                                    name='boxName'
                                    label='Box Name'
                                    placeholder='e.g Box 1002'
                                    value={form.getFieldValue("boxName") || ''} 
                                    style={{width: '220px'}}
                                />
                                <MyInput
                                    name='vendorName'
                                    label='Vendor Name'
                                    placeholder='e.g John Doe'
                                    value={form.getFieldValue("vendorName") || ''} 
                                    style={{width: '220px'}}
                                    // onChange={(e)=> debouncedSearchHandler(e.target.value, 1, 20)}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        className='margin-top'
                                        icon={<FilterOutlined />}
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Space>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                            scroll={{x: 1000}}
                            pagination={{ 
                                hideOnSinglePage: true, 
                                total: 12,
                                // total: pagination?.totalRecords,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions:['20', '50', '100'],
                                //onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                                showTotal: total => <Button>Total: {total}</Button>
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export {TransactionReport}