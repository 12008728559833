import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAllBoxes = createAsyncThunk('getAllBoxes',
    async (object, { dispatch, getState }) => {
        
        const {userToken}= checkAuthorization()
        dispatch(gettingAddBoxDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({...object})
        };
        fetch(domainUrl + `/viewAllAndroidBoxes`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addboxDetailResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addboxDetailClear())
                message.error(error) 
            })
    }
)
export const getBoxesStatus = createAsyncThunk('getAllBoxes',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({...object})
        };
        fetch(domainUrl + `/getBoxStatus`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addBoxesStatusResponse(result?.data))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addboxDetailClear())
                message.error(error) 
            })
    }
)
export const getPortals = createAsyncThunk('getAllBoxes',
    async (boxID, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };
        fetch(domainUrl +  `/getPortals/${boxID}`, requestOptions)
        .then(response => {
            if(response?.status!== 200)
                throw "auth error"
            return response.json()
        })
        .then(result => {
            if (result?.success)
                dispatch(getPortalsResponse(result?.data))
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error) 
            dispatch(getPortalsResponse([]))
        })
    }
)

const AddBoxSlice = createSlice({
    name: 'addEditBox',
    initialState: {
        data: null,
        loading: false,
        portals: [],
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            vendorName: null,
            boxName: null,
            customerName: null,
            serialNo: null,
            status: null,
            order: 1
        },
    },
    reducers: {
        gettingAddBoxDetail: state => {
            state.loading=true
        },
        addboxDetailResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        addboxDetailClear: (state)=>{
            state.data= []
            state.loading= false
        },
        addBoxesStatusResponse: (state, action)=>{
            let tempArr= action?.payload
            state.data= state?.data?.map((box ,index)=> ({...box, dateTime: tempArr[index]?.dateTime}))
        },
        getPortalsResponse: (state, action) => {
            state.portals= action?.payload
        },
        clearPortalsResponse: (state) => {
            state.portals= []
        },
        changeAddEditBoxFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingAddBoxDetail, addboxDetailResponse, addboxDetailClear, addBoxesStatusResponse, getPortalsResponse, clearPortalsResponse,
    changeAddEditBoxFilters, setPages
} = AddBoxSlice.actions;
export default AddBoxSlice.reducer;