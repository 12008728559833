import { Table, Typography, Modal, Button} from "antd"
import { boxCsvSampleData } from "../../../data"
const {Text}= Typography

const CSVFormatGuideModal= ({visible, onClose})=>{
    const data= [...boxCsvSampleData]
    const columns = [
        {
            title: <Text>serial #</Text>,
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: <Text>Box Name</Text>,
            dataIndex: 'boxName',
            key: 'boxname',
        },
        {
            title: <Text>Master Portal</Text>,
            dataIndex: "masterPortal",
            key: "masterPortal",
        },
        {
            title: <Text>Mac Address</Text>,
            dataIndex: "macAddress",
            key: "macAddress",
        },
        {
            title: <Text>Update Url</Text>,
            dataIndex: "updateUrl",
            key: "updateUrl",
        },
        {
            title: <Text>Customer Name</Text>,
            dataIndex: "customerName",
            key: "customerName",
        }
    ]
    return (
        <Modal
            title="CSV Format Guide"
            centered
            open={visible}
            onCancel={onClose}
            footer={null}
            width={950}
        >
            <Table
                size='large'
                columns={columns} 
                dataSource={data}     
                pagination={{ 
                    hideOnSinglePage: true, 
                    total: data?.length,
                    pageSize: 10,
                    defaultPageSize: 10,
                    size: "default",
                    pageSizeOptions:['20', '50', '100'],
                    showTotal: total => <Button>Total: {total}</Button>
                }}
            />
        </Modal>
    )
}
export {CSVFormatGuideModal}