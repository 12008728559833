import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'


export const getActivityLog = createAsyncThunk('getAllBoxes',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingActivityLog())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({activityType: object?.activityType})
        };
        fetch(domainUrl +  `/getActivityLogs`, requestOptions)
        .then(response => {
            if(response?.status!== 200)
                throw "auth error"
            return response.json()
        })
        .then(result => {
            if (result?.success)
                {
                    dispatch(activityLogResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error) 
            dispatch(activityLogClear())
        })
    }
)
export const clearActivityLog = createAsyncThunk('getAllBoxes',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({activityType: object?.activityType})
        };
        fetch(domainUrl +  `/clearActivityLogs`, requestOptions)
        .then(response => {
            if(response?.status!== 200)
                throw "auth error"
            return response.json()
        })
        .then(result => {
            if (result?.success)
                dispatch(activityLogClear())
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error) 
        })
    }
)

const ActivityLog = createSlice({
    name: 'activityLog',
    initialState: {
        data: null,
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 30,
            totalRecords:0,
        },
        filters: {
        },
    },
    reducers: {
        gettingActivityLog: state => {
            state.loading=true
        },
        activityLogResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        activityLogClear: (state)=>{
            state.data= []
            state.loading= false
        },
        changeActivityLogFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingActivityLog, activityLogResponse, activityLogClear,
    changeActivityLogFilters, setPages
} = ActivityLog.actions;
export default ActivityLog.reducer;