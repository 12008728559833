import { useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Checkbox } from "antd"
import {  MyInput } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import "./index.css"

const {Title, Text}= Typography

const Login = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    const [forgotPassword, setForgotPassword]= useState(false)

    
    
    const login = () => {
        if(forgotPassword)
            appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
        else
            appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    }

    


    return (
        <div className='login-form-cover'>
            <div className="center">
                <Row className="login-form border-grey justify-center">
                    <Col 
                        md={10} sm={0} xs={0}
                        className="flex-col-align-end brand-bg pt-5"
                    >
                            <Space 
                                direction="vertical" 
                                className="px-4 justify-center height-100"
                                size={0} 
                            >
                                <Title level={1} className="mb-5 text-white">Welcome</Title>
                                <Text className="text-white">
                                    Master Vendor Panel - Welcome to the IPTV Vendor Panel, your gateway to seamless control and management, our platform empowers you with unparalleled command over your application. From comprehensive user management to real-time analytics, streamline your operations effortlessly. Unlock the full potential of your IPTV service. Where every click propels your service forward.
                                </Text>
                                <div className="justify-center">
                                    <Image 
                                        src="/assets/images/iptv-smarters-svgrepo-com.svg" 
                                        className="w-100" 
                                        preview={false} 
                                        alt="iptv-logo"
                                    />
                                </div>
                                
                            </Space>
                    </Col>
                    <Col 
                        md={14} sm={24} xs={24} 
                        className="bg-white login-form-right-side"
                        style={{background: 'url(/assets/images/iptv-smarters-svgrepo-com-sm.svg)'}}
                    >
                        <Space 
                            direction='vertical' 
                            className='px-4 justify-center height-100'
                            size={15}
                            >
                            <div>
                                <Title 
                                    level={5} 
                                    className="my-0 pt-3"
                                >
                                    Login to access your dashboard
                                </Title>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                style={{ width: '100%' }}
                                onFinish={login}
                            >
                                <Row>
                                    <Col span={24}>
                                        <MyInput
                                            autoFocus
                                            name="email"
                                            label="Email"
                                            required
                                            message='please enter username'
                                            size='large'
                                            value={form.getFieldValue("email") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <MyInput
                                            name="password"
                                            type='password'
                                            label="Password"
                                            size='large'
                                            required
                                            message='please enter password'
                                            value={form.getFieldValue("password") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox  className="mb-3">Remember me</Checkbox>
                                    </Col>
                                    <Col span={24} className="center">
                                        <Button 
                                            type="primary"
                                            htmlType="submit" 
                                            size="large"
                                            // block
                                            loading={loading}
                                            >
                                            Login Panel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </Col>
                </Row>
                <Text strong className="pt-3 light-grey-text">
                    Version <span className="brand-color">0.2</span> Beta Release
                </Text>
            </div>
        </div>
    )
}
export {Login}