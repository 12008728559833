import { useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Space, Row, Col, Typography, Card, Table } from 'antd'
import { ActionButton, ActivityLogModal, AlertModal, FileEditModal, ModuleTopHeading } from '../../components'
import { actionsApi, ApiCalls, TableLoader, timeDifference, utcToLocal } from '../../shared'
import { DeleteOutlined, EditOutlined} from '@ant-design/icons'
import { AddEditBoxDrawer, Filter, ImportDevices } from '../../components/AndroidBox'
const { Text } = Typography
const PING_INTERVAL = 30000; // Ping every 30 seconds


const AddEditBox = () => {
    
    const dispatch = useDispatch()
    const { data, loading, filters, pagination } = useSelector(state => state?.addEditBox)
    const [visible, setVisible]= useState(false)
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
    const [ editbox, setEditBox ] = useState(null)
    const [ visiblelog, setVisibleLog ] = useState(false)
    const [ editmodal, setEditModal ] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [ deletebox, setDeleteBox ] = useState()
    const {userData}= useSelector(state => state?.login)

    console.log(data)
    useEffect(()=>{
        let intervalId
        if(data?.length){
            intervalId = setInterval(()=>{boxesStatusCall(pagination?.pageNo, pagination?.pageSize)}, PING_INTERVAL);
        }
        else
            clearInterval(intervalId)
        return () => clearInterval(intervalId)
    }, [data])

    const boxesStatusCall= (pageNo=1, pageSize=10)=>{
        dispatch(actionsApi?.getBoxesStatus({ ...filters, pageNo, pageSize }))
    }
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAllBoxes({ ...filters, pageNo, pageSize }))
    }
    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            {
                key: '1',
                text: 'Edit',
                onSelect: () => setEditModal(true),
            },
            {
                key: '2',
                text: 'Delete',
                onSelect: () => setVisibleDeleteModal(true)
            },
        ]
    }

    const columns = [
        {
            title: <Text>Serial #</Text>,
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: <Text>Box Name</Text>,
            dataIndex: 'boxName',
            key: 'boxName',
            fixed:'left',
            width:180,
        },
        {
            title: <Text>Customer Name</Text>,
            dataIndex: 'customerName',
            key: 'customerName',
            fixed:'left',
            width:180,
        },
        {
            title: <Text>Mac Address</Text>,
            dataIndex: 'macAddress',
            key: 'macAddress',
            render: col => (<Text className='brand-color'>{col}</Text>)
        },
        {
            title: <Text>{(userData?.role==='masterAdmin')?'Master Vendor':'Vendor User'}</Text>,
            dataIndex:userData?.role==='masterAdmin'? 'vendorName':userData?.role==='masterVendor'?'addedByName':'addedByName',
            key: userData?.role==='masterAdmin'? 'vendorName':userData?.role==='masterVendor'?'addedByName':'addedByName',
        },
        {
            title: <Text>Url's</Text>,
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: <Text>Url Update</Text>,
            dataIndex: 'updateUrl',
            key: 'updateUrl',
        },
        {
            title: <Text>Status</Text>,
            dataIndex: 'status',
            key: 'status',
            render: (_, row) => {
                return (
                    !row?.dateTime || (row?.dateTime && (timeDifference(utcToLocal(row?.dateTime)) > 60000)) ? 
                    <Space>
                        <img src="/assets/icons/offline.png" width={'12px'} alt="" />
                        <Text style={{transform:"translateY(-2px)",display:'block'}}>offline</Text>
                    </Space>
                    :
                    <Space align='center'>
                        <img src="/assets/icons/online.png" width={'12px'} alt="" />
                        <Text style={{transform:"translateY(-2px)",display:'block'}}>online</Text>
                    </Space>
                );
            }
        },
        {
            title: <Text>Last seen</Text>,
            dataIndex: 'dateTime',
            key: 'dateTime',
            render: dateTime => utcToLocal(dateTime)
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed:'right',
            width:100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit Box'
                        icon={<EditOutlined className='green-color'/>}
                        onClick={()=>{setVisible(true);setEditBox(row?.id)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color'/>}
                        onClick={()=>{setVisibleDeleteModal(true);setDeleteBox({id:row?.id, name: row?.boxName})}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
          },
    ]
    const removeRow= async (ID)=>{
        const result = await ApiCalls?.AddEditBoxApi?.DeleteAndroidApi(ID)
        if(result)
            return 1
        return 0
    }


  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col span={24}>
                    <ModuleTopHeading
                        name='Add/Edit box'
                        onClick={()=>{setVisible(true)}}
                    />
                </Col>
                <Col span={24}>
                    <Filter/>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        rowKey={(record) => record.id}
                        rowSelection={rowSelection}
                        columns={columns} 
                        dataSource={data} 
                        className='table-cover'
                        scroll={{x: 1500}}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={
                            {
                                ...TableLoader,
                                spinning: loading
                            }
                        }
                    />
                </Col>
            </Row>
        </Card>
        <ImportDevices />
        <AddEditBoxDrawer
            visible={visible}
            editbox={editbox}
            getAllApplications={call}
            onClose={()=> {setVisible(false);setEditBox(null)}}
        />
        <ActivityLogModal 
            visiblelog = {visiblelog}
            onClose={()=>setVisibleLog(false)}
        />
        <FileEditModal 
            editmodal={editmodal}
            onClose={()=>setEditModal(false)}
        />
        <AlertModal
            visible={visibleDeleteModal}
            row={deletebox}
            removeRow={removeRow}
            endCall={call}
            onClose={()=>{setVisibleDeleteModal(false);setDeleteBox(null)}}
        />
    </div>
  )
}

export {AddEditBox}