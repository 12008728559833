import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	dashboard,
	addEditBox,
	vendorUser,
	activityLog
} from "./action"
const store = configureStore({
	reducer: {
		login,
		dashboard,
		addEditBox,
		vendorUser,
		activityLog
	},
})
export default store