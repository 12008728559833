import { useDispatch, useSelector } from "react-redux"
import { Drawer, Table, Button, Typography} from "antd"
import { useEffect } from "react"
import { actionsApi, TableLoader, utcToLocal} from "../../../shared"
const {Text}= Typography

const ActivityLogDrawer= ({visible, onClose})=>{

    const dispatch= useDispatch()
    const {data, loading, pagination}= useSelector(state => state?.activityLog)
    const columns = [
        {
            title: <Text>Time</Text>,
            dataIndex: 'dateTime',
            key: 'dateTime',
            render: dateTime => utcToLocal(dateTime)
        },
        {
            title: <Text>Activity</Text>,
            dataIndex: 'activity',
            key: 'activity',
            render: (activity, row) => <Text type={row?.activityStatus}>{activity}</Text>
        },
    ]
    useEffect(()=>{
        if(visible)
            dispatch(actionsApi?.getActivityLog({activityType: 2, pageNo:1, pageSize:30}))
    }, [visible])
    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getActivityLog({ activityType: 2, pageNo, pageSize }))
    }
    const clearActivityLog= ()=> dispatch(actionsApi?.clearActivityLog({activityType: 2}))
    return (
        <Drawer
            title={'Activity Log'}
            onClose={onClose}
            open={visible}
            width={850}
            footer={
                <div className="justify-center">
                    <Button type="ghost" onClick={clearActivityLog}>Clear Log</Button>
                </div>
            }
        >
            <Table 
                size='large'
                rowKey={(record) => record.id}
                columns={columns} 
                dataSource={data} 
                pagination={{
                    hideOnSinglePage: true,
                    total: pagination?.totalRecords,
                    pageSize: pagination?.pageSize,
                    defaultPageSize: pagination?.pageSize,
                    current: pagination?.pageNo,
                    size: "default",
                    pageSizeOptions: ['10', '20', '50', '100'],
                    onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                    showTotal: (total) => <Button>Total: {total}</Button>,
                }}
                loading={
                    {
                        ... TableLoader,
                        spinning: loading
                    }
                }
            />
        </Drawer>
    )
}
export {ActivityLogDrawer}