import React,{ useState } from 'react'
import { Button, Space, Row, Col, Form, Typography,Dropdown, Card, Table } from 'antd'
import { ActionButton, ActivityLogModal, AlertModal, MyInput, ModuleTopHeading } from '../../components';
import { DeleteOutlined, FileDoneOutlined, FilterOutlined } from '@ant-design/icons';
const { Text } = Typography


const ServerPortal = () => {
    const [form] = Form.useForm()
    const [visiblelog, setVisibleLog]= useState(false)
    const [visiblemodal, setVisibleModal] = useState(false);
    const [ showname, setShowName ] = useState()
  
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Most downloads</a>,
            key: '2',
        },
        {
            label: <a href="#">Less downloads</a>,
            key: '3',
        },
    ];
    

    // function searchHandler(name, pageNo=1, pageSize=20) {
    //     dispatch(actionsApi?.getAddBoxDetail({ ...filters, pageNo, pageSize }))
    // }

    // const onClick = ({ key }) => {
    //     key = parseInt(key) + 1
    //     setOrder(key)
    //     filter(key)
    // };

    // const onSelectChange = (selectedRowKeys) => {
    //     setSelectedRowKeys(selectedRowKeys);
    //     console.log('Selected Row Id', selectedRowKeys)
    // };

    // function debounce(func, delay) {
    //     return function(...args) {
    //         clearTimeout(timer)
    //         timer = setTimeout(() => {
    //             func.apply(this, args)
    //         }, delay)
    //     };
    // }
    // const debouncedSearchHandler = debounce(searchHandler, 400)


  
    const columns = [
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macaddress',
            key: 'macaddress',
        },
        {
            title: <Text>TYPE</Text>,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: <Text>URL ADDRESS</Text>,
            dataIndex: 'urlAddress',
            key: 'urlAddress',
        },
        {
            title: <Text>USER NAME / XTREME</Text>,
            dataIndex: 'userName',
            key: 'userName',
        },
          {
            title: <Text>PASSWORD / XTREME</Text>,
            dataIndex: 'password',
            key: 'password',
          },
          {
            title: <Text>ACTION</Text>,
            key: 'action',
            fixed:'right',
            width:100,
            align:'center',
            render: (_, row) => (
                <ActionButton
                    title='Delete Box'
                    icon={<DeleteOutlined className='danger-color'/>}
                    onClick={()=>{setVisibleModal(true); setShowName(row?.macaddress)}}
                    type='ghost'
                    danger
                />
            ),
          },
      ];
      const data = [
          {
              key: '1',
              macaddress:   <Text>01:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
          {
              key: '2',
              macaddress:   <Text>02:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
          {
              key: '3',
              macaddress:   <Text>03:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
          {
              key: '4',
              macaddress:   <Text>04:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
          {
              key: '5',
              macaddress:   <Text>05:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
          {
              key: '6',
              macaddress:   <Text>06:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
          {
              key: '7',
              macaddress:   <Text>07:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
          {
              key: '8',
              macaddress:   <Text>08:B0:D0:63:C2:26</Text>,
              type:   <Text>Stalker</Text>,
              urlAddress:   <Text>http://www.abc.com</Text>,
              userName:   <Text>xyz</Text>,
              password:   <Text>abcdefghi</Text>,
          },
      ];
  
    return (
        <div>
            <Card className='radius-12 border0'>
                <Row gutter={[12,12]}>
                    <Col span={24}>
                        <ModuleTopHeading 
                            name='Server Portal'
                        />
                    </Col>

                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Space  className='align-center mbl-wrap' >
                                <MyInput
                                    name='userName'
                                    label='User Name'
                                    placeholder='e.g John Doe'
                                    value={form.getFieldValue("userName") || ''} 
                                    style={{width: '220px'}}
                                    // onChange={(e)=> debouncedSearchHandler(e.target.value, 1, 20)}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        className='margin-top'
                                        icon={<FilterOutlined />}
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                                <Button
                                    type='primary'
                                    icon={<FileDoneOutlined />}
                                    onClick={()=> setVisibleLog(true)}
                                    className='btn-color-other margin-top'
                                >
                                    Activity log
                                </Button>
                            </Space>
                        </Form>
                    </Col>


                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                            scroll={{x: 1000}}
                            pagination={{ 
                                hideOnSinglePage: true, 
                                total: 12,
                                // total: pagination?.totalRecords,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions:['20', '50', '100'],
                                //onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                                showTotal: total => <Button>Total: {total}</Button>
                            }}
                        />
                    </Col>
                </Row>                            
                <ActivityLogModal 
                    visiblelog={visiblelog}
                    onClose={()=>setVisibleLog(false)}
                />
                
                <AlertModal
                    visiblemodal={visiblemodal}
                    showname={showname}
                    onClose={()=>setVisibleModal(false)}
                />
            </Card>
        </div>
    )
}

export {ServerPortal}