import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Drawer, Form, Row, Col ,Button, Space} from "antd"
import { actionsApi, checkAuthorization, domainUrl } from "../../../shared"
import { ApiCalls } from "../../../shared/ApiCalls"
import { methodAllow, methodUrlType, yesNo } from "../../../shared/lookups"
import Portals from "./Portals"
import { MyInput, MySelect } from "../../Forms"

const AddEditBoxDrawer = ({visible, onClose,editbox, getAllApplications }) => {
    
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  

    useEffect(() => {
        if (visible && editbox) {
            EditboxApi(editbox)
        } else {
            form.resetFields();
        }
    }, [visible, editbox]);

    const updateCreateApi = async (formData) => {
        setLoading(true)
        const result = await ApiCalls.AddEditBoxApi.updateCreateApi({...formData,id:editbox});
        setLoading(false)
        if(result){
            closeDrawer()
            getAllApplications()
        }
    }

    //edit android api
    const EditboxApi = (id) => {
        const {userToken} = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/editAndroidBox/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success)
                {
                
                    form.setFieldsValue(result?.androidBox) 
                    dispatch(actionsApi?.getPortals(id))
                }
            else
                throw  'error'
        })
        .catch(error => {alert(error)})
    }

    const onFinish = async () => {
        try {
            let formData= await form.validateFields()
            formData= {
                ...formData,
                portals: formData?.portals?.map((portal, index)=> {
                    portal={
                        ...portal, 
                        selectUrl: index +1, 
                        urlType: methodUrlType?.find(fin=> fin?.name=== portal?.urlType)?.id
                    }
                    if(portal?.urlType !== 3){
                        delete portal?.userName 
                        delete portal?.password
                    }
                    return portal
                })
            }
            updateCreateApi(formData)
        } catch (error) {
            console.error("Form validation error:", error);
        }
    }
    const closeDrawer= ()=>{
        dispatch(actionsApi?.clearPortalsResponse())
        form.resetFields()
        onClose()
    }

  return (
    <div>
        <Drawer
            title={editbox?'Edit Box':'Add Box'}
            onClose={closeDrawer}
            open={visible}
            width={800}
            footer={
                <Space className="w-100">
                    <Button 
                        onClick={closeDrawer}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={()=> form.submit()}
                    >
                        {
                            editbox? 'Update box' : 'Save'
                        }
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={onFinish}
              
            >
                <Row gutter={16}  >
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            autoFocus
                            label='Android box name'
                            name='boxName'
                            placeholder='e.g Box1001'
                            required
                            message='please enter android box name'
                            value={form.getFieldValue("boxName") || ''} 
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Serial number'
                            name='serialNo'
                            placeholder='e.g 1234'
                            required
                            message='please enter serial number'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Master portal'
                            name="masterPortal" 
                            placeholder='select master portal'
                            options={yesNo}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='MAC address'
                            name='macAddress'
                            placeholder='e.g 00:B0:D0:63:C2:46'
                            required
                            message='please enter mac address'
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const cleanedValue = inputValue.replace(/\W/g, '');
                                const formattedMacAddress = cleanedValue.match(/.{1,2}/g)?.join(':').slice(0, 17) || '';
                                form.setFieldsValue({ ...form?.getFieldsValue(), macAddress: formattedMacAddress });
                            }} 
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Update url'
                            name="updateUrl" 
                            required
                            message='please choose update url'
                            placeholder='Choose updated url'
                            options={methodAllow}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Customer name'
                            name='customerName'
                            placeholder='e.g John Doe'
                            required
                            message='Please enter customer name'
                            value={form.getFieldValue("customerName") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <Portals {...{form}}/>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    </div>
  )
}

export {AddEditBoxDrawer}