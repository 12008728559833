const isUnAuthorize= ()=>{
    const userToken = localStorage.getItem('token')
    if(!userToken)
        return 1
    return 0
}
const checkAuthorization= ()=>{
    const tokenString = localStorage.getItem('token');
    let userToken = JSON.parse(tokenString)
    userToken= `Bearer ${userToken}`
    const user= localStorage?.getItem("user")
    const userID= JSON.parse(user)?.id
    if(!userToken || !userID){
        localStorage.clear()
        window.location.href='/'
    }
    return ({userToken, userID})
}
export {isUnAuthorize, checkAuthorization}