import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAllVendorUser = createAsyncThunk('getAllVendorUser',
    async (object, { dispatch, getState }) => {
        
        const {userToken}= checkAuthorization()
         console.log("getallvendorobject",object)
        dispatch(gettingVendorUser())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
             body: JSON.stringify({name: object?.name, order: object?.order})
        };
         let url = object.role==="masterAdmin"?`/viewAllVendor`:`/viewAllVendorUser`
      
        fetch(domainUrl + url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                   
                    let response = object.role==="masterAdmin"?result.applications:result.vendorUsers
                      dispatch(vendorUserResponse(response))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(vendorUserClear())
                message.error(error) 
            })
    }
)

const VendorUser = createSlice({
    name: 'vendorUser',
    initialState: {
        data: null,
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            name: null,
            order: 1,
        },
    },
    reducers: {
        gettingVendorUser: state => {
            state.loading=true
        },
        vendorUserResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        vendorUserClear: (state)=>{
            state.data= null
            state.loading= false
        },
        changeVendorUserFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { gettingVendorUser, vendorUserResponse, vendorUserClear, changeVendorUserFilters, setPages } = VendorUser.actions;
export default VendorUser.reducer