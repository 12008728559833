import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Skeleton } from 'antd'
import { actionsApi } from '../../shared'
import { DashboardCard } from './DashboardCard';

const CardComponent = ({userData}) => {

    const dispatch= useDispatch()
    const {data, loading}= useSelector(state => state?.dashboard)
    const [state, setState]= useState([])
   
    console.log("userdata",userData)
    useEffect(()=>{
        dispatch(actionsApi?.getStatistics())
    }, [])

    useEffect(()=>{
        if(data){
            setState([
                {title: "Total token", count: data?.totalToken, icon: 'totalvendor.png'},
                {title: "Remaining token", count: data?.remainingToken,  icon: 'remaintoken.png'},
                {title: "Registered Devices", count: data?.registeredDevices,  icon: 'totaldev.png'}
            ])
        }
        else 
            setState([])
    }, [data])

  return (
    <div>
        <Row gutter={[16, 24]}>
            {
                loading ?
                new Array(4)?.fill(null)?.map((_, index)=>
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={'skelton-card-' + index}>
                        <Skeleton.Button 
                            active 
                            size='large' 
                            shape='square'
                            block
                            style={{width:'100%', height:'82px'}}
                            className='radius-20'
                        />
                    </Col>
                )
                :
                state?.map((data, index) =>
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={'dashboard-card-' + index}>
                        <DashboardCard data={data}/>
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {CardComponent}