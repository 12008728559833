import { domainUrl } from "../../constants/apiconstants/domainUrl";
import { message } from "antd";
import { checkAuthorization } from "../../helpers";

const getTokenDetail= (masterVendorID)=>{
   
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
        method: 'get',
        headers: myHeaders,
        redirect: 'follow',
    }

    return (
        fetch(domainUrl + `/getTokenDetails/${masterVendorID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
           
            if (result?.success) {
                return result
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const AddUpdateCreateVendor = (formData) => {
    const {userToken} = checkAuthorization();
    
    var myHeaders = new Headers();
    myHeaders.append(
        "Content-Type", "application/json",
    );
    myHeaders.append('Authorization', userToken);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    let url = formData.userRole==="masterAdmin"?(formData.id ? '/updateVendor' :'/createVendor'):(formData.id ? '/updateVendorUser' :'/createVendorUser')
    
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
          
            if (result?.success) {
                message.success('User Created Successfully !!');
                return 1
            } else {
                message.error(result?.message || 'Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
}


const deleteVendorUser=(deletevendor)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    let url = deletevendor?.role==="masterAdmin"?`/deleteVendor/${deletevendor?.ID}`:`/deleteVendorUser/${deletevendor?.ID}`
    return(
        fetch(domainUrl + url, requestOptions)
        .then(response => {
            if(response?.status!== 200)
                throw "auth error"
            return response.json()
        })
        .then(result => {
            if (result?.success) {
                message.success(deletevendor?.role==="masterAdmin"?'Master Vendor deleted successfully !!':'Vendor User deleted successfully !!')
                return 1
            } else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
  }

const VendorUserApi = {
    getTokenDetail,
    AddUpdateCreateVendor,
    deleteVendorUser
}

export {VendorUserApi}